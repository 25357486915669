.wrapper {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 40px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
}

.tag {
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dot {
  width: 10px;
  height: 10px;
  background: var(--secondary);
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
  animation-delay: calc(var(--index) * 0.1s);

  &:nth-child(2) {
    background: var(--primary);
  }

  &:nth-child(3) {
    background: var(--primary);
  }

  &:nth-child(4) {
    background: var(--primary);
  }

  &:nth-child(5) {
    background: var(--primary);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
